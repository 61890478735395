 <template>
  <div id="StuPersonalAwardDetail">
    <van-cell-group>
      <van-cell style="padding-top: 17px;padding-bottom: 17px;">
        <template #title>
          <span class="custom-title hidden-text"> <span style="color:rgba(35, 171, 114, 1)">{{row.schoolYear +'年'}}</span>{{type ==='syd'?'生源地贷款':'校园地贷款'}}</span>
        </template>
        <template #label>
          <div class="custom-centent  hidden-text">
            <svg-icon icon-class="date" size="15" /> 时间: {{row.createTime}}
          </div>
          <div class="custom-centent margin6 hidden-text">
            <van-icon name="refund-o" size="15" /> 金额: {{row.loanAmount}}
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <!-- 审核信息 -->
    <van-cell-group title="审核信息">
      <audit ref="audit" :app-params="{ coreKey: $route.query.id,  mkCode: 'DK',coreCode:  type === 'xyd' ? 'XYDDK' : 'SYDDK', status:$route.query.status,level:3}" />
    </van-cell-group>
    <van-cell-group title="贷款详情">
      <van-field v-model="row.loanType" readonly label="贷款类型" />
      <van-field v-model="row.annualLivingAmount" readonly label="每年生活费(元)" />
      <van-field v-model="row.annualAccomAmount" readonly label="每年住宿费(元)" />
      <van-field v-model="row.loanTime" readonly label="贷款期限(月)" />
      <van-field v-model="row.loanAmount" readonly label="贷款总金额" />
      <van-field v-model="row.loanReason" readonly rows="3" class="textarea-style" label="申请理由" type="textarea" maxlength="300" />
    </van-cell-group>

  </div>
</template>

<script>
import { statustag, parseTime } from "@/utils";
import audit from "@/view/modules/common/audit";

export default {
  name: "LoanDetail",
  components: { audit },
  dicts: ["xj_shzt"],
  data() {
    return {
      row: {
        id: "",
        loanType: "syd",
        cdbOrNot: false,
        loanReason: "",
        receiptCheckCode: "",
        annualAccomAmount: "",
        annualLivingAmount: "",
        annualLoan: "",
        loanAmount: "",
        graConfirm: null,
        loanTime: 12,
      },
      type: "",
    };
  },
  created() {
    let data = this.$route.query;
    this.$nextTick((e) => {
      this.row = data;
      this.type = data.loanType;
      this.row.loanType = data.loanType === "syd" ? "生源地贷款" : "校园地贷款";
      setTimeout(() => {
        // 业务ID 审批项目类型 审批业务类型 目前到审批状态
        this.$refs["audit"].init(
          data.id,
          "DK",
          this.type === "xyd" ? "XYDDK" : "SYDDK",
          parseInt(data.approvalStatus),
          2
        );
      }, 1000);
    });
  },
  methods: {
    statustag,
    parseTime,
    onClickLeft() {
      this.row = {
        id: null,
        stuNo: null,
        stuName: null,
        awardName: null,
        awardAt: null,
        remark: null,
        awardDept: null,
        fileId: [],
      };
      this.$router.go(-1);
    },
    getSpztLabel(shzt) {
      let approvalStatus = parseInt(shzt);
      return this.dict.xj_shzt
        .filter((item) => {
          return parseInt(item.value) === approvalStatus;
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
  },
};
</script>

<style  lang='scss' scoped>
@import "~@/assets/styles/student/loan.scss";
::v-deep .van-field__label {
  width: 120px;
}
.textarea-style {
  height: 100% !important;
}
.radius-st {
  position: absolute;
  right: 5%;
  top: 20%;
}
.van-cell__title {
  text-align: left;
  min-width: 70%;
}
.van-cell__title span {
  display: inline-block;
  text-align: left;
  word-break: break-all;
}

.audit {
  top: 17px;
  right: 10px;
  border-radius: 0 13px 0 13px;
}
</style>
